<template>
  <div>
    <div class="row align-items-center mb-2">
      <div class="col-6">
        <h6 class="font-weight-bolder">Company's Instruction Lists</h6>
      </div>
      <div class="col-6 text-right" v-if="permissionAllowed === true">
        <button class="e-btn-green e-btn font-weight-bold" data-target="#add-instruction" data-toggle="modal">
          <font-awesome-icon icon="plus" class="mr-1 force-white-all"/>
          NEW INSTRUCTION
        </button>
      </div>
    </div>
    <table class="table table-sm table-bordered text-center">
      <thead>
      <tr>
        <th style="width: 10em">Target Ship/s</th>
        <th style="width: 10em">Type of Instruction</th>
        <th style="width: 13em">Sent Date</th>
        <th style="width: 40em">Description</th>
        <th style="width: 8em">Status</th>
        <th style="width: 8em">Progress</th>
        <th style="width: 6em">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="instruction in companyInstructions">
        <td class="align-middle  text-sm-center">
          <button class="e-btn btn-xs e-btn-blue-gray" data-toggle="modal" data-target="#vessel-lists"
                  @click="setVessels(instruction)">
            {{ instruction.related_party | toUpper }}
          </button>
        </td>
        <td class="align-middle font-weight-bold">{{ instruction.instruction_type | toUpper }}</td>
        <td class="align-middle font-weight-bolder">{{ instruction.sent_at }}</td>
        <td class="align-middle">{{ instruction.title | toUpper }}</td>
        <td class="align-middle text-white" :class="{
                  'bg-success font-weight-bolder': instruction.sent_at && !instruction._cancelled_at,
                  'bg-secondary font-weight-bolder': !instruction.sent_at && !instruction._cancelled_at,
                  'bg-danger font-weight-bolder': instruction.cancelled_at,
                }">
          {{
            instruction.sent_at && !instruction.cancelled_at ? 'SENT' :
              !instruction.sent_at && !instruction.cancelled_at ? 'DRAFT' :
                'CANCELLED'
          }}
        </td>
        <td class="align-middle">
          <template v-if="instruction.sent_at">
            <span class="font-weight-bolder"
                  :class="{'text-success' : instruction.done > 0 }">{{ instruction.done }}</span> /
            <span class="font-weight-bolder">{{ instruction.sent }}</span>
          </template>
        </td>
        <td class="align-middle">
          <div class="btn-group">
            <button type="button" data-target="#view-instruction"
                    data-toggle="modal"
                    @click="viewCompanyInstruction(instruction)"
                    class="btn btn-primary btn-xs e-text-white">
              <font-awesome-icon class="force-white-all mr-1 fa-1x" icon="eye"/>
              View
            </button>
            <div class="btn-group dropright" role="group" v-if="!instruction.cancelled_at && permissionAllowed === true">
              <button type="button" class="btn btn-primary btn-xs force-white-all dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <font-awesome-icon icon="chevron-right" class="force-white-all fa-1x"></font-awesome-icon>
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header text-sm font-weight-bold">Shortcuts</p>
                <a class="dropdown-item btn-success" @click="sendCompanyInstruction(instruction)"
                   href="#" v-if="!instruction.sent_at">
                  <font-awesome-icon icon="envelope" class="force-success-all mr-1"/>
                  Send to Vessels</a>
                <a class="dropdown-item btn-danger" href="#" @click="cancelCompanyInstruction(instruction)"
                   v-if="instruction.sent_at">
                  <font-awesome-icon icon="ban" class="mr-1 force-danger-all"/>
                  Cancel Instruction</a>
                <a class="dropdown-item btn-danger" href="#"
                   @click="exportInstruction(instruction)">
                  <font-awesome-icon icon="download" class="text-success l mr-1"/>
                  Export Instruction</a>
                <a class="dropdown-item btn-danger" href="#" v-if="!instruction.sent_at"
                   @click="removeCompanyInstruction(instruction)">
                  <font-awesome-icon icon="trash" class="force-danger-all mr-1"/>
                  Delete</a>
              </div>

            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <CIVesselList id="vessel-lists" :vessels="vesselList" :related_party="relatedPartyString"/>
    <CIModal id="add-instruction">
      <template v-slot:header>
        <h5 class="font-weight-bold">{{ addCompanyInstructionHeader }}</h5>
        <button type="button" class="close" @click="resetCompanyInstruction('add-instruction',true)">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template v-slot:body>
        <form>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction</h6>
            </div>
            <div class="col-lg-10">
              <input type="text" v-model="companyInstruction.instruction.title"
                     class="form-control form-control-sm mb-2" required
                     placeholder="Input Instruction Title Here">
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction Detail</h6>
            </div>
            <div class="col-lg-10">
              <vue-editor :editor-toolbar="toolbar" v-model="companyInstruction.instruction.detail"></vue-editor>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Related Parties</h6>
            </div>
            <div class="col-lg-10">
              <button type="button" class="e-btn e-btn-" :class="{
                'e-btn-green' : selectedPartyString,
                'e-btn-blue-gray'  : !selectedPartyString,
              }" data-toggle="modal" data-target="#relatedPartiesModal">
                {{ selectedPartyString ? selectedPartyString : 'SELECT VESSEL GROUP' }}
              </button>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Type of Instruction</h6>
            </div>
            <div class="col-4">
              <select name="" class="form-control form-control-sm"
                      v-model="companyInstruction.instruction.instruction_type_id">
                <option :value="instructionType.id" v-for="instructionType in airCiTypeKeys">
                  {{ instructionType.name | toUpper }}
                </option>
              </select>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Attachmentsss</h6>
            </div>
            <div class="col-4">
              <input ref="ciAttachment" class="form-control form-control-sm" id="formFileSm" type="file" multiple>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-4 text-right">
              <button class="e-btn e-btn-blue e-btn-md mr-2" type="button"
                      @click.prevent="saveCompanyInstruction('add-instruction',false)">
                Save as Draft
              </button>
              <button v-if="selectedPartyString"
                      @click.prevent="saveCompanyInstruction('add-instruction',true)"
                      class="e-btn e-btn-orange e-btn-md" type="button">
                Save and Submit
              </button>
            </div>
          </div>
        </form>
      </template>
    </CIModal>
    <CIModal id="view-instruction">
      <template v-slot:header>
        <h5 class="font-weight-bold">View Company Instruction</h5>
        <button type="button" class="close" @click="resetCompanyInstruction('view-instruction',!instruction.sent_at)">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template v-slot:body>
        <div style="display: flex; justify-content: end;" class="mb-2">
          <button type="button"
                  @click="exportInstruction(instruction)"
                  class="mr-2 e-btn e-btn-blue-gray force-white-all">
            <font-awesome-icon class="force-white-all mr-1 fa-1x" icon="download"/>
            Export Instruction
          </button>
        </div>

        <form>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction</h6>
            </div>
            <div class="col-lg-10">
              <template v-if="!instruction.sent_at">
                <input type="text" v-model="instruction.title"
                       :disabled="!toEdit"
                       class="form-control form-control-sm mb-2" required
                       placeholder="Input Instruction Title Here">
              </template>

              <template v-else>
                <input type="text" class="form-control form-control-sm" :value="instruction.title | toUpper"
                       disabled
                       name="instruction_type"
                       id="instruction_type">
              </template>

            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Instruction Detail</h6>
            </div>
            <div class="col-lg-10">
              <template v-if="!instruction.sent_at && toEdit">
                <vue-editor :editor-toolbar="toolbar"
                            v-model="instruction.detail"></vue-editor>
              </template>
              <template v-else>
                <div id="textArea" class="pre-wrap-txt textarea-display-card text-left" v-html="instruction.detail || '' "/>
              </template>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Type of Instruction</h6>
            </div>
            <div class="col-4">
              <template v-if="!instruction.sent_at">
                <select name="" class="form-control form-control-sm"
                        v-model="instruction.instruction_type_id" :disabled="!toEdit">
                  <option :value="instructionType.id" v-for="instructionType in airCiTypeKeys">
                    {{ instructionType.name  | toUpper}}
                  </option>
                </select>
              </template>
              <template v-else>
                <input type="text" class="form-control form-control-sm"
                       :value="instruction.instruction_type | toUpper"
                       disabled
                       name="instruction_type"
                       id="instruction_type">
              </template>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Related Parties</h6>
            </div>
            <div class="col-lg-4">
              <template v-if="!instruction.sent_at">
                <select class="form-control form-control-sm"
                        :disabled="!toEdit"
                        @change="setRelatedParties"
                        v-model="instruction.related_parties_id">
                  <option :value="null">--- SELECT COMPANY INSTRUCTION ---</option>
                  <option :value="party.id" v-for="party in relatedParties">{{ party.party_name | toUpper }}</option>
                </select>
              </template>
              <template v-else>
                <input type="text" class="form-control form-control-sm"
                       :value="instruction.related_party | toUpper"
                       disabled
                       name="instruction_type"
                       id="instruction_type">
              </template>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Attachments</h6>
            </div>
            <div class="col-10" v-if="!toEdit">
              <div class="row ml-1">
                <div class="col-2 ml-0 pl-0"  v-for="attachment in instructionFiles">
                  <other-comp :no-delete="true"
                              @viewOther="viewInstructionAttachment(attachment,false)"
                              :attachment="attachment" attachment-name="name" />
                </div>
              </div>

            </div>
            <template v-else>
              <div class="col-10" v-if="instructionFiles.length > 0">
                <div class="row ml-1">
                  <div class="col-2 ml-0 pl-0"  v-for="attachment in instructionFiles">
                    <other-comp :no-delete="false"
                                @viewOther="viewInstructionAttachment(attachment,false)"
                                @deleteOther="deleteInstructionAttachment(attachment)"
                                :attachment="attachment" attachment-name="name" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4" v-else>
                <input ref="ciUpdateAttachment" class="form-control form-control-sm" id="formFileSm" multiple type="file">
              </div>
            </template>

          </div>
          <div class="row align-items-center mb-2">
            <div class="col-lg-2">
              <h6 class="font-weight-bold">Vessels</h6>
            </div>
            <div class="col-10">
              <template v-if="!instruction.sent_at">
                <div class="row">
                  <div class="col-lg-3" v-for="vessel in vesselKeys" :key="vessel.id">
                    <div class="form-check">
                      <input type="checkbox"
                             :id="vessel.id"
                             class="form-check-input mt-0"
                             :value="vessel.id"
                             v-model="vessels"
                             @change="setSelectedVessel"
                             :disabled="!toEdit"/>
                      <label class="form-check-label" :for="vessel.id">{{ vessel.name }}</label>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="row" style="max-height: 300px; overflow: auto">
                  <div class="col-6 mb-1" v-for="vessel in instruction.vessels ">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <font-awesome-icon icon="ship"/>
                        {{ vessel.vessel | toUpper }}
                      </div>
                      <div class="col-3">
                              <small class="font-weight-bolder" :class="{
                          'text-danger':vessel.cancelled_at,
                          'text-574e4e':!vessel.cancelled_at && vessel.job_status_id ==1,
                          'text-056cb4':!vessel.cancelled_at && vessel.job_status_id ==2,
                          'text-42b983':!vessel.cancelled_at && vessel.job_status_id ==3,
                          }">
                                <template v-if="vessel.cancelled_at">
                                  CANCELLED
                                </template>
                                <template v-else>
                                  {{ vessel.job_status | toUpper }}
                                </template>
                          </small>
                      </div>
                      <div class="col-3">
                               <small class="font-weight-bolder" :class="{
                              'text-danger':vessel.cancelled_at,
                              'text-4c4c4c':!vessel.cancelled_at && vessel.acknowledgement_status_id ==1,
                              'text-042454':!vessel.cancelled_at && vessel.acknowledgement_status_id == 2,
                              'text-42b983':!vessel.cancelled_at && vessel.acknowledgement_status_id == 3,
                              'text-1b4f72':!vessel.cancelled_at && vessel.acknowledgement_status_id == 4,
                            }">
                                 <template v-if="vessel.cancelled_at">
                                   CANCELLED
                                 </template>
                                 <template v-else>
                                   {{ vessel.acknowledgement_status | toUpper }}
                                 </template>
                        </small>
                      </div>
                      <div class="col-2">
                        <button type="button" class="e-btn e-btn-blue e-btn-xs"
                                @click="viewVesselResponse(vessel)"
                                data-toggle="modal"
                                data-target="#view-vessel-response">
                          <font-awesome-icon icon="eye" class="force-white-all"/>
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col-4 text-right">

              <button v-if="!instruction.sent_at && !toEdit && permissionAllowed === true" class="e-btn e-btn-blue e-btn-md mr-2" type="button"
                      @click.prevent="toEdit=!toEdit">
                Edit
              </button>

              <template v-if="!instruction.sent_at && toEdit">
                <button v-if="!instruction.sent_at" class="e-btn e-btn-blue e-btn-md mr-2" type="button"
                        @click.prevent="saveCompanyInstruction('view-instruction',false,true)">
                  Save as Draft
                </button>
                <button v-if="instruction.instruction_type_id && !instruction.sent_at"
                        @click.prevent="saveCompanyInstruction('view-instruction',true,true)"
                        class="e-btn e-btn-orange e-btn-md" type="button">
                  Save and Submit
                </button>
              </template>
            </div>
          </div>
        </form>
      </template>
    </CIModal>
    <CIModal id="view-vessel-response">
      <template v-slot:header>
        <template v-if="!!vesselResponse">
          <h5 class="font-weight-bold">Vessel Response : {{vesselResponse.vessel | toUpper}}</h5>
        </template>
        <button type="button" class="close" @click="resetVesselResponse('view-vessel-response',true)">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template v-slot:body>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Job Completion:</h6>
          </div>
          <div class="col-lg-4">
            <template v-if="vesselResponse">
              <select class="form-control form-control-sm" v-model="vesselResponse.job_status_id"
                      name="job-status" id="job-status" disabled>
                <option :value="jobStatus.id" v-for="jobStatus in jobStatuses">{{jobStatus.name}}</option>
              </select>
            </template>
          </div>
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Job Done:</h6>
          </div>
          <div class="col-lg-10">
            <template v-if="!!vesselResponse">
              <div id="textArea" class="pre-wrap-txt textarea-display-card" v-html="vesselResponse.response || '' "/>
            </template>
          </div>
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Attachments:</h6>
          </div>
          <div class="col-lg-10">
            <div class="row mb-2">
              <div class="col-2"  v-for="attachment in instructionAttachments">
                <other-comp :no-delete="true"
                            @viewOther="viewInstructionAttachment(attachment)"
                            :attachment="attachment" attachment-name="name" />
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Acknowledgement:</h6>
          </div>
          <div class="col-lg-5">
            <div class="row">
              <template v-if="$route.name !== 'IaCompanyInstructions'">
                <div class="col-auto">
                  <template v-if="!!vesselResponse">
                    <select class="form-control form-control-sm" v-model="vesselResponse.acknowledgement_status_id"
                            :disabled="vesselResponse.cancelled_at"
                            name="job-status" id="job-status">
                      <option :value="ackStatus.id" v-for="ackStatus in acknowledgementStatuses">{{ackStatus.name}}</option>
                    </select>
                  </template>
                </div>
                <div class="col-auto">
                  <button type="button" class="e-btn e-btn-green" @click="submitResponse" v-if="!vesselResponse.cancelled_at">Submit</button>
                </div>
              </template>
              <template v-if="$route.name === 'IaCompanyInstructions'">
                <template v-if="user.is_vessel === false">
                  <div class="col-auto">
                    <template v-if="!!vesselResponse">
                      <template v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.edit-acknowledgment-status') === false">
                        {{ acknowledgementStatuses.length > 0 ? acknowledgementStatuses.filter(status => status.id === vesselResponse.acknowledgement_status_id)[0]?.name : ''}}
                      </template>
                      <template v-else>
                        <select class="form-control form-control-sm" v-model="vesselResponse.acknowledgement_status_id"
                                :disabled="vesselResponse.cancelled_at"
                                name="job-status" id="job-status">
                          <option :value="ackStatus.id" v-for="ackStatus in acknowledgementStatuses">{{ackStatus.name}}</option>
                        </select>
                      </template>
                    </template>
                  </div>
                  <div class="col-auto" v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.edit-acknowledgment-status') === true">
                    <button type="button" class="e-btn e-btn-green" @click="submitResponse" v-if="!vesselResponse.cancelled_at">Submit</button>
                  </div>
                </template>
                <template v-else>
                  <div class="col-auto">
                    <template v-if="!!vesselResponse">
                      {{ acknowledgementStatuses.length > 0 ? acknowledgementStatuses.filter(status => status.id === vesselResponse.acknowledgement_status_id)[0]?.name : '' }}
                    </template>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>

        <div class="row align-items-center mb-2">
          <div class="col-lg-2">
            <h6 class="font-weight-bold">Comments:</h6>
          </div>
          <div class="col-lg-10">
              <template v-if="!!vesselResponse">
                <CIComment :key="vesselResponse.id" v-if="!!vesselResponse.id"  :vessel-response="vesselResponse" :ciVesselId="vesselResponse.id"></CIComment>
              </template>
           </div>
        </div>
      </template>
    </CIModal>
    <RelatedPartiesModal ref="relatedParties" @selected-values="setSelectedVessels" v-bind:report-vessel-id="entity_vessel_id"></RelatedPartiesModal>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import CIVesselList from "./CIVesselList.vue";
import CIModal from "./CIModal.vue";
import RelatedPartiesModal from "../accident-incident/company-instruction/RelatedPartiesModal.vue";
import {AlertService} from "../../../services/AlertService";
import OtherComp from "../../elements/OtherComp.vue";
import {
  CI_INDEX,
  CI_VESSEL_INDEX,
  COMPANY_INSTRUCTION_EXPORT,
  VESSEL_DOWNLOAD_ALL_FILE
} from "../../../services/ConstantService";
import axios from "axios";
import {FileService} from "../../../services/FileService";
import CIComment from "./CIComment.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import vessel from "../../../views/Vessel.vue";

export default {
  name:"CIList",
  components: {CIComment, OtherComp, RelatedPartiesModal, CIModal, CIVesselList},
  props: {
    entity: {
      type: String,
      default: null,
    },
    entity_id: {
      type: Number | String,
      default: null,
    },
    entity_vessel_id: {
      type: Number | String,
      default: null,
    },
  },
  mixins: [permissionMixin],
  data() {
    return {
      vessels: [],
      relatedParty: null,
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      companyInstruction: {
        type: this.entity,
        id: this.entity_id,
        instruction: {
          title: '',
          detail: '',
          instruction_type_id: 1,
          related_parties_id: null,
          vessels: [],
        },
      },
      initialVessel:null,
      initialSelectedParty:null,
      instruction: {},
      toEdit: false,
      selectedPartyString: null,
      vesselResponse:{},
      vesselList:[],
      relatedPartyString:'',
    }
  },
  computed: {
    ...mapGetters(['companyInstructions',
        'accidentIncidentReport',
        'pscReport',
        'airCiTypeKeys',
        'relatedParties',
        'relatedPartyVessels',
        'vesselKeys',
        'jobStatuses',
        'acknowledgementStatuses',
        'instructionAttachments',
        'instructionFiles',
        'openedCompanyInstruction',
      ]),
    permissionAllowed() {
      if (this.$route.name === 'PscDeficiencyCompanyInstruction') {
        return this.isAllowed('psc.main.deficiency.overview.ci.edit');
      }

      if (this.$route.name === 'IaCompanyInstructions') {
        return this.isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.create') || this.isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.edit') || this.isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.delete')
      }

      return this.isAllowed('accident-incident.company-instruction.index.create') || this.isAllowed('accident-incident.company-instruction.index.view-edit') || this.isAllowed('accident-incident.company-instruction.index.delete');
    },
    addCompanyInstructionHeader() {
      if (this.entity === 'air') {
        return 'Add New Accident/Incident Company Instruction';
      }

      if (this.entity === 'psc_d') {
        return 'Add New PSC Inspection Company Instruction';
      }

      return 'Add New Objective Evidence Company Instruction'
    }
  },
  methods: {
    ...mapActions([
      'addCompanyInstruction',
      'updateCompanyInstruction',
      'deleteCompanyInstruction',
      'getRelatedPartyVessels',
      'sendVesselResponse',
      'getVesselAttachments',
      'getCompanyInstructionAttachments',
      'deleteCompanyInstructionAttachment'
    ]),
    exportInstruction(instruction){
      const url = COMPANY_INSTRUCTION_EXPORT.replace('{ci_id}', instruction.id)

      axios({
        method: 'get',
        url,
        responseType: 'blob',
      })
        .then((response) => {
          const today = new Date().toISOString().slice(0, 10)
          this.forceFileDownload(response, `company_instruction_${today}.zip`)
        })
        .catch(() => console.log('error occured'))
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    viewCompanyInstruction(instruction) {
      this.instruction = instruction;
      this.setVessels(instruction)
      this.getCompanyInstructionAttachments(instruction)
    },
    setVessels(instruction) {
      this.vesselList=!!instruction.vessels ? instruction.vessels : null
      this.vessels = !!instruction.vessels ? instruction.vessels.map(vessel => vessel.vessel_id) : []
      this.initialVessel=!!instruction.vessels ? instruction.vessels.map(vessel => vessel.vessel_id) : []
      this.initialSelectedParty=instruction?.related_parties_id ?? ""
      this.relatedParty = instruction?.related_parties_id ?? ""
      this.relatedPartyString=instruction?.related_party ?? ''
    },
    async resetCompanyInstruction(id, withDialog = false) {
      this.toEdit = false;
      if (!withDialog) {
        $(`#${id}`).modal('hide');
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$refs.relatedParties.reset()
        this.$refs.ciUpdateAttachment.value=null;
        this.$refs.ciAttachment.value=null;
      } else {
        if (await AlertService.cancelAlert()) {
          $(`#${id}`).modal('hide');
          this.$refs.relatedParties.reset()
          this.$refs.ciUpdateAttachment.value=null;
          this.$refs.ciAttachment.value=null;
          Object.assign(this.$data, this.$options.data.apply(this));
        }
      }
    },
    async resetVesselResponse(id, withDialog = false){
      if (!withDialog) {
        $(`#${id}`).modal('hide');
        this.vesselResponse=null
      } else {
        if (await AlertService.cancelAlert()) {
          $(`#${id}`).modal('hide');
          this.vesselResponse=null

          if (id === 'view-vessel-response') {
            setTimeout(() => {
              $('body').addClass('modal-open');
            }, 350);
          }
        }
      }
    },
    setSelectedVessels(event) {
      const {selectedParty, selectedVessels} = event
      this.companyInstruction.instruction.related_parties_id = selectedParty
      this.selectedPartyString = this.relatedParties.filter(party => party.id == selectedParty)[0]?.party_name ?? null;
      this.companyInstruction.instruction.vessels = selectedVessels;
    },
    async saveCompanyInstruction(elId, is_sent = false, is_update = false) {
      if (is_update) {
        if (await AlertService.confirmUpdateAlert(`Do you want to update company's instruction`)) {
          if (is_sent) {
            this.instruction.is_sent = true
            swal.fire({
              title:'Sending Company Instructions',
              allowEscapeKey:false,
              allowEnterKey:false,
              allowOutsideClick:false,
            })
            swal.showLoading();
          }
          this.instruction.vessels = this.vessels
          const formData=this.setInstructionData(this.instruction,this.$refs.ciUpdateAttachment)
          const response = await this.updateCompanyInstruction({params:formData,id:this.instruction.id})
          if (response) {
            if(is_sent) swal.close()
            AlertService.successAlert('Company Instruction Updated Successfully', 'Update Company Instruction');
            this.resetCompanyInstruction(elId);
            this.$emit('resetCompanyInstructions');
            this.$refs.relatedParties.reset();
            this.$refs.ciUpdateAttachment.value=null;
          }
        }
      } else {
        if (await AlertService.confirmUpdateAlert(`Do you want to save new company's instruction ?`, 'Yes, save it')) {
          if (is_sent) {
            this.companyInstruction.instruction.is_sent = true
            swal.fire({
              title:'Sending Company Instructions',
              allowEscapeKey:false,
              allowEnterKey:false,
              allowOutsideClick:false,
            })
            swal.showLoading();
          }


          const formData=this.setInstructionData(this.companyInstruction,this.$refs.ciAttachment)
          console.log('foo', this.$refs.ciAttachment)
          const response = await this.addCompanyInstruction(formData)
          if (response) {
            if (is_sent) {
              swal.close();
            }
            AlertService.successAlert('Company Instruction Added successfully', 'Add Company Instruction');
            this.resetCompanyInstruction(elId);
            this.$emit('resetCompanyInstructions');
            this.$refs.relatedParties.reset();
            this.$refs.ciAttachment.value=null
          }
        }
      }

    },

    setInstructionData(form,file){
      if (typeof file === "undefined") {
        file = null
      }
      let formData =new FormData()
      if(file?.files.length){
        Array.prototype.forEach.call(file.files,file=>{
          formData.append('attachments[]',file)
        })
      }
      Object.keys(form).forEach(item=>{
        if(typeof form[item] == 'object' && form[item]) {
          Object.keys(form[item]).forEach(item2=>{
            if(Array.isArray(form[item][item2])){
              form[item][item2].forEach((vessel,index)=>{
                formData.append(`${item}[${item2}][${index}]`,vessel)
              })
            }
            else {
              formData.append(`${item}[${item2}]`,form[item][item2])
            }
          })
        }
        else {
          formData.append(item,form[item])
        }
      });

      formData.append('_source','Company Instruction')

      return formData;
    },
    async sendCompanyInstruction(instruction) {
      if (await AlertService.companyInstructionAlertSend('Do you want to send this instruction to vessels ?')) {
        const companyInstruction = {id: instruction.id,is_sent:true}
        const response = await this.updateCompanyInstruction({params:companyInstruction,id:companyInstruction.id});
        if (response) {
          AlertService.successAlert('Company Instruction has been sent successfully', 'Send Company Instruction');
          this.$emit('resetCompanyInstructions');
        }
      }

    },
    async cancelCompanyInstruction(instruction) {
      if (await AlertService.companyInstructionAlertCancel('Do you want to cancel this company instruction to vessels ?')) {
        const companyInstruction = {id: instruction.id,is_cancelled:true}
        const response = await this.updateCompanyInstruction({params:companyInstruction,id:companyInstruction.id});
        if (response) {
          AlertService.successAlert('Company Instruction has been cancelled successfully', 'Cancel Company Instruction');
          this.$emit('resetCompanyInstructions');
        }
      }
    },
    async removeCompanyInstruction(instruction) {
      if (await AlertService.confirmDeleteAlert('Delete Company Instruction', 'Do you want to delete this company instruction ?', 'Yes, proceed')) {
        const companyInstruction = {id: instruction.id}
        const response = await this.deleteCompanyInstruction(companyInstruction);
        if (response) {
          AlertService.successAlert('Company Instruction has been deleted successfully', 'Delete Company Instruction');
          this.$emit('resetCompanyInstructions');
        }
      }
    },
    async setRelatedParties() {
      this.vessels = [];
      let params = {
        related_party_id: this.instruction.related_parties_id ?? null,
        vessel_id: this.$route.path.startsWith('/psc') ? this.pscReport.vessel_id : this.accidentIncidentReport.vessel_id
      }
      await this.getRelatedPartyVessels(params);
      this.vessels = this.relatedPartyVessels
      this.initialVessel=this.relatedPartyVessels
      this.initialSelectedParty=this.instruction.related_parties_id
    },
    setSelectedVessel(){
      if(this.initialVessel.length == this.vessels.length){
        var stringedNum= JSON.stringify(this.initialVessel.sort())
        var stringedNum2= JSON.stringify(this.vessels.sort())
        if(stringedNum===stringedNum2){
          this.instruction.related_parties_id=this.initialSelectedParty;
        }
        else {
          this.instruction.related_parties_id=8
        }
      }
      else {
        if(this.vessels.length === this.vesselKeys.length){
          this.instruction.related_parties_id=1;
        }
        else {
          this.instruction.related_parties_id=8
        }
      }
    },
    viewVesselResponse(response){
      console.log(response)
      this.vesselResponse=response
      this.getVesselAttachments({id:this.vesselResponse.id})
    },
    async submitResponse() {
      if(await AlertService.confirmUpdateAlert('Do you want to update your response ? ')){
        const response = await this.sendVesselResponse({id:this.vesselResponse.id,acknowledgement_status_id:this.vesselResponse.acknowledgement_status_id})
        if(response){
          AlertService.successAlert('Response Updated Successfully','Submit Response');
          this.resetVesselResponse('view-vessel-response');
          this.instruction=response;
        }
      }
    },
    async viewInstructionAttachment(attachment,fromVessel=true) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();

      const mainRoute= fromVessel ? CI_VESSEL_INDEX : CI_INDEX

      const url= [mainRoute,attachment.referencable_id,'attachment',attachment.id].join('/')

      const response = await axios.get(url ,{responseType: 'blob'})

      if (response && response.status == 200) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response.data,attachment)
        }
        else {
          await FileService.downloadDocument(response.data,attachment,'name');
        }
        swal.close();
      }
      else {
        AlertService.errorAlert('File not exists',"View Vessel Attachment")
      }
    },

    async deleteInstructionAttachment(attachment){
      if (await AlertService.confirmDeleteAlert('Delete Company Instruction Attachment', 'Do you want to delete this attachment ?', 'Yes, proceed')) {
        const response = await this.deleteCompanyInstructionAttachment(attachment);
        if (response) {
          AlertService.successAlert('Company Instruction Attachment has been deleted successfully', 'Delete Company Instruction Attachment');
        }
      }

    },
  },
  mounted() {
    this.companyInstruction.type = this.entity
    this.companyInstruction.id = this.entity_id
  },


}
</script>
<style scoped>
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  background: white;
}

.dropdown-toggle::after {
  display: none;
}
</style>
